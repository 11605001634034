import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { AccountsSlice } from "../slices/Accounts";
import { AttendeesSlice } from "../slices/Attendees";
import { AutomationsSlice } from "../slices/Automations";
import { AvailabilitiesSlice } from "../slices/Availabilities";
import { BookingsSlice } from "../slices/Bookings.js";
import { ChatsSlice } from "../slices/Chats";
import { DeveloperUsersSlice } from "../slices/DeveloperUsers";
import { DiscountsSlice } from "../slices/Discounts";
import { DocumentsSlice } from "../slices/Documents";
import { EditAccountSlice } from "../slices/EditAccount";
import { EditEventSlice } from "../slices/EditEvent";
import { EditTemplateSlice } from "../slices/EditTemplate";
import { EventDocumentsSlice } from "../slices/EventDocuments";
import { EventsSlice } from "../slices/Events";
import { ExhibitionsSlice } from "../slices/Exhibitions";
import { ExhibitionsTrackingsSlice } from "../slices/ExhibitionsTrackings";
import { GroupsSlice } from "../slices/Groups";
import { FailedJobsSlice, ScheduledJobsSlice } from "../slices/Jobs";
import { LocationsSlice } from "../slices/Locations";
import { MeetingsSlice } from "../slices/Meetings";
import { OptionsSlice } from "../slices/Options";
import { PaymentAccountsSlice } from "../slices/PaymentAccounts.js";
import { PostsSlice } from "../slices/Posts";
import { ProductsSlice } from "../slices/Products";
import { ProfileMeetingsSlice } from "../slices/ProfileMeetings";
import { ProfileTrackingsSlice } from "../slices/ProfileTrackings";
import { ProfilesSlice } from "../slices/Profiles";
import { SessionPromotionStatisticsSlice } from "../slices/SessionPromotionStatistics";
import { SessionsSlice } from "../slices/Sessions";
import { StatisticsSlice } from "../slices/Statistics";
import { StreamsSlice } from "../slices/Streams";
import { SupportUsersSlice } from "../slices/SupportUsers";
import { TemplatesSlice } from "../slices/Templates";
import { TicketsSlice } from "../slices/Tickets";
import { TracksSlice } from "../slices/Tracks";
import { UserNotesSlice } from "../slices/UserNotes";
import { UserTicketsSlice } from "../slices/UserTickets";
import { UsersSlice } from "../slices/Users";
import Auth from "./Auth";
import Common from "./Common";
import Settings from "./Settings";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    accounts: AccountsSlice.reducer,
    supportUsers: SupportUsersSlice.reducer,
    developerUsers: DeveloperUsersSlice.reducer,
    users: UsersSlice.reducer,
    events: EventsSlice.reducer,
    attendees: AttendeesSlice.reducer,
    documents: DocumentsSlice.reducer,
    exhibitions: ExhibitionsSlice.reducer,
    locations: LocationsSlice.reducer,
    meetings: MeetingsSlice.reducer,
    posts: PostsSlice.reducer,
    sessions: SessionsSlice.reducer,
    streams: StreamsSlice.reducer,
    templates: TemplatesSlice.reducer,
    tracks: TracksSlice.reducer,
    automations: AutomationsSlice.reducer,
    scheduledJobs: ScheduledJobsSlice.reducer,
    failedJobs: FailedJobsSlice.reducer,
    statistics: StatisticsSlice.reducer,
    editEvent: EditEventSlice.reducer,
    editAccount: EditAccountSlice.reducer,
    editTemplate: EditTemplateSlice.reducer,
    sessionPromotionStatistics: SessionPromotionStatisticsSlice.reducer,
    tickets: TicketsSlice.reducer,
    options: OptionsSlice.reducer,
    discounts: DiscountsSlice.reducer,
    bookings: BookingsSlice.reducer,
    paymentAccounts: PaymentAccountsSlice.reducer,
    availabilities: AvailabilitiesSlice.reducer,
    chats: ChatsSlice.reducer,
    exhibitionsTrackings: ExhibitionsTrackingsSlice.reducer,
    eventDocuments: EventDocumentsSlice.reducer,
    groups: GroupsSlice.reducer,
    products: ProductsSlice.reducer,
    profiles: ProfilesSlice.reducer,
    profileMeetings: ProfileMeetingsSlice.reducer,
    profileTrackings: ProfileTrackingsSlice.reducer,
    userNotes: UserNotesSlice.reducer,
    userTickets: UserTicketsSlice.reducer,
  });

export default createRootReducer;
