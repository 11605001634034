import { Select } from "antd";
import React, { useMemo } from "react";
import { getCurrentEnv, setCurrentEnv } from "util/Api";

import generatedGitInfo from "../../generatedGitInfo.json";
import { apiEnvDict } from "../../util/Api";

const renderOption = (envItem) => (
  <Select.Option key={envItem.value} value={envItem.value}>
    {envItem.title}
  </Select.Option>
);

export const ApiSwitcher = () => {
  const defaultApi = useMemo(() => {
    if (generatedGitInfo.gitBranch === "production") return "production";
    return getCurrentEnv();
  }, []);

  return (
    <Select defaultValue={defaultApi} onChange={setCurrentEnv}>
      {apiEnvDict.map(renderOption)}
    </Select>
  );
};
