import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { userPasswordReset, userSignIn } from "../appRedux/actions";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import { getAuthUser } from "../appRedux/selectors/Auth";
import { LinkButton } from "../components/Buttons/LinkButton";
import { ApiSwitcher } from "../components/ApiSwitcher";

const ForgotButton = styled(LinkButton)`
  margin-bottom: 20px;
`;

const styles = {
  rememberForgotContainer: { display: "flex", justifyContent: "space-between" },
};

const SignIn = (props) => {
  const dispatch = useDispatch();
  const authUser = useSelector(getAuthUser);

  const [hasBeenPasswordForgoten, setHasBeenPasswordForgoten] = useState(false);

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    if (!hasBeenPasswordForgoten) return dispatch(userSignIn(values));
    return dispatch(userPasswordReset(values));
  };

  useEffect(() => {
    if (authUser !== null) {
      props.history.push("/");
    }
  }, [authUser, props.history]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg" />
            <div className="gx-app-logo-wid">
              <h1>
                {hasBeenPasswordForgoten ? (
                  "Reset password"
                ) : (
                  <IntlMessages id="app.userAuth.signIn" />
                )}
              </h1>
            </div>
            <ApiSwitcher />
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                rules={[
                  { required: true, message: "The input is not valid E-mail!" },
                ]}
                name="email"
              >
                <Input placeholder="Email" />
              </Form.Item>
              {!hasBeenPasswordForgoten && (
                <Form.Item
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                  name="password"
                >
                  <Input type="password" placeholder="Password" />
                </Form.Item>
              )}
              {!hasBeenPasswordForgoten && (
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
              )}
              <div style={styles.rememberForgotContainer}>
                <Form.Item>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    {hasBeenPasswordForgoten ? (
                      "Reset password"
                    ) : (
                      <IntlMessages id="app.userAuth.signIn" />
                    )}
                  </Button>
                </Form.Item>
                <ForgotButton
                  onClick={(event) => {
                    event.preventDefault();
                    setHasBeenPasswordForgoten(!hasBeenPasswordForgoten);
                  }}
                >
                  {hasBeenPasswordForgoten ? "Sign In" : "Forgot password"}
                </ForgotButton>
              </div>
            </Form>
          </div>
          <InfoView />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
