import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import { getUserType } from "../appRedux/selectors/Auth";
import { MenuItems } from "../constants/MenuItems";

const App = ({ match }) => {
  const userType = useSelector(getUserType);
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        {MenuItems.map((item) => {
          if (!item.visibleFor.includes(userType)) return null;
          return (
            <Route
              key={item.key}
              path={`${match.url}${item.key}`}
              component={asyncComponent(() =>
                import(`./${item.componentName}`)
              )}
            />
          );
        })}
      </Switch>
    </div>
  );
};

export default App;
