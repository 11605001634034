export const registrationFormContentHtmlInitialValue = `
<div class="er">
    <div class="er-form-recapture-script"></div>
    <form class="er-form" data-form-id="formid" data-recaptcha-sitekey="valueofrecaptcha">
        <div class="er-form-wrapper">
            <div class="er-form-group-wrapper">
                <div class="er-form-group">
                    <label class="translate translate-label-salutation required default-required"></label>
                    <select class="translate translate-select-salutation" name="salutation"></select>
                    <div class="er-form-error error-salutation"></div>
                </div>
                <div class="er-form-group">
                    <label class="translate translate-label-first-name"></label>
                    <input class="translate translate-placeholder-first-name" type="text" name="first_name" />
                    <div class="er-form-error error-first-name"></div>
                </div>
                <div class="er-form-group">
                    <label class="translate translate-label-last-name required default-required"></label>
                    <input class="translate translate-placeholder-last-name" type="text" name="last_name"   />
                    <div class="er-form-error error-last-name"></div>
                </div>
            </div>
            <div class="er-form-group-wrapper">
                <div class="er-form-group">
                    <label class="translate translate-label-job-title required"></label>
                    <input name="job_title" type="text"  />
                    <div class="er-form-error error-job-title"></div>
                </div>
                <div class="er-form-group">
                    <label class="translate translate-label-company-name required default-required"></label>
                    <input name="company_name" type="text"  />
                    <div class="er-form-error error-company-name"></div>
                </div>
            </div>
            <div class="er-form-group-wrapper">
                <div class="er-form-group">
                    <label class="translate translate-label-email required default-required"></label>
                    <input name="email" type="text"  />
                    <div class="er-form-error error-email"></div>
                </div>
            </div>
            <div class="er-form-group-wrapper">
                <div class="er-form-group">
                    <label class="translate translate-label-job-level required"></label>
                    <select class="translate translate-select-job-level" name="job_level"></select>
                    <div class="er-form-error error-job-level"></div>
                </div>
            </div>
            <div class="er-form-group-wrapper">
                <div class="er-form-group">
                    <label class="translate translate-label-company-size"></label>
                    <select  class="translate translate-select-company-size" name="company_size"></select>
                    <div class="er-form-error error-company-size"></div>
                </div>
                <div class="er-form-group">
                    <label class="translate translate-label-address-country required default-required"></label>
                    <select class="translate translate-select-address-country" name="address_country"></select>
                    <div class="er-form-error error-address-country"></div>
                </div>
            </div>
            <div class="er-form-group-wrapper">
                <div class="er-form-group">
                    <label class="translate translate-label-industry"></label>
                    <select class="translate translate-select-company-industry default-required" name="company_industry"></select>
                    <div class="er-form-error error-company-industry"></div>
                </div>
            </div>
            <div class="er-form-group-wrapper">
                <div class="er-form-group">
                    <label class="translate translate-label-business-type required"></label>
                    <select class="translate translate-select-business-type" name="company_type_of_business"></select>
                    <div class="er-form-error error-business-type"></div>
                </div>
                <div class="er-form-group">
                    <label class="translate translate-label-job-responsibility required"></label>
                    <select class="translate translate-select-job-responsibility required" name="job_responsibility"></select>
                    <div class="er-form-error error-job-responsibility"></div>
                </div>
            </div>
            <div class="er-form-group-wrapper">
                <div class="er-form-group">
                    <label class="translate translate-label-phone required default-required"></label>
                    <input name="contact_phone" type="text" placeholder="" />
                    <div class="er-form-error"></div>
                </div>
                <div class="er-form-group">
                    <label class="translate translate-label-mobile"></label>
                    <input name="contact_mobile" type="text" placeholder="" />
                    <div class="er-form-error error-mobile"></div>
                </div>
            </div>
            <div class="er-form-group-wrapper">
                <div class="er-form-group checkbox">
                    <input type="checkbox" name="gdpr" value="gdpr">
                    <label class="translate translate-label-gdpr required label-inline er-gdpr"></label>
                    <div class="er-form-error error-gdpr"></div>
                </div>
            </div>
            <div class="er-recaptcha-wrapper">
                <div class="g-recaptcha" id="g-recaptcha-key" data-sitekey="valueofrecaptcha"></div>
                <div class="er-recaptcha-error"></div>
            </div>
            <div class="er-form-group-wrapper">
                <div class="er-form-group form">
                    <button class="translate translate-button-save" name="register" type="submit"></button>
                    <div class="er-form-error error-save"></div>
                </div>
            </div>
        </div>
    </form>
    <div class="er-confirmation er-hide"></div>
    <div class="er-messages er-hide"></div>
</div>
<style>
    .er {
        all: initial;
        * {
            all: unset;
        }
        position: relative;
        display: inline-block;
    }
    .er-confirmation,
    .er-messages{
        position: relative;
        display: block;
    }
    .er-hide {
        display: none;
    }
    .er-form {
        position: relative;
    }
    .er-form-wrapper {
        position: relative;
        display: inline-grid;
    }
    .er-form-group-wrapper {
        position: relative;
        display: inline-flex;
    }
    .er-form-group {
        position: relative;
        padding: 0.5em 1em;
        width: 100%;
    }
    .er-form-group label {
        display: block;
        font-weight: 700;
        font-size: 16px;
        line-height: 1.3;
        margin: 0 0 4px 0;
        font-family: Poppins, Poppins;
    }
    .er-form-group input,
    select {
        font-family: Nunito;
        background-color: #EFEFEF;
        box-sizing: border-box;
        border-radius: 20px;
        color: #242424;
        display: block;
        float: none;
        font-size: 14px;
        border: 0px solid #E2E2E2;
        padding: 0px 20px;
        height: 38px;
        width: 100%;
        line-height: 1.4;
    }
    .er-form-group button {
        background-color: #eee;
        border: 1px solid #ddd;
        color: #333;
        font-weight: 700;
        font-size: 1em;
        padding: 10px 15px;
        font-family: Poppins, Poppins;
        cursor: pointer;
    }
    .er-required-label {
        color: #FF0000;
        font-weight: 400;
        padding-left: 0.3em;
    }
    .er-form-group-checkbox input {
        cursor: pointer;
    }
    .er-form-group.error input,
    .er-form-group.error select {
        border: 1px solid #CC0000;
    }
    .er-form-error {
        display: none;
        color: #990000;
        font-size: 12px;
    }
    .er-form-group.error .er-form-error {
        display: block;
    }
    .hide {
        display: none !important;
    }
</style>
`;
