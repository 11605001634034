import { createTablePageSlice } from "../helpers/createTablePageSlice";

export const UsersSlice = createTablePageSlice({
  reducerName: "users",
  getListEndpoint: "v1/admin/users",
  createRecordEndpoint: "v1/admin/users",
  configs: {
    createRecordModal: {
      createTitle: "Create User",
      fields: [
        {
          type: "select",
          name: "salutation",
          label: "Salutation",
          required: false,
          options: [
            {
              text: "mr",
              value: "mr",
            },
            {
              text: "mrs",
              value: "mrs",
            },
          ],
        },
        {
          type: "input",
          name: "first_name",
          label: "First name",
          required: true,
        },
        {
          type: "input",
          name: "last_name",
          label: "Last name",
          required: true,
        },
        {
          type: "input",
          name: "email",
          label: "Email",
          required: true,
          contentType: "email",
        },
        {
          type: "input",
          name: "company_name",
          label: "Company name",
        },
        {
          type: "input",
          name: "job_title",
          label: "Job Title",
        },
        {
          type: "input",
          name: "address_country",
          label: "Country name",
        },
        {
          type: "input",
          name: "phone",
          label: "User phone number",
        },
      ],
    },
  },
});
