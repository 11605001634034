import moment from "moment-timezone";

export const datesAdapter =
  (fieldsList = [], getTimezone = (record) => record?.timezone) =>
  (record = {}) => {
    return fieldsList.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: moment(acc[cur]).tz(getTimezone(record)),
      }),
      record
    );
  };

export const filtered = (raw, allowed) =>
  Object.keys(raw)
    .filter((key) => allowed.includes(key))
    .reduce((obj, key) => {
      obj[key] = raw[key];
      return obj;
    }, {});
