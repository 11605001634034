/**
 * User types who has access to app
 */
export const ROLES = {
  support: "support",
  developer: "developer",
  leader: "leader",
  system: "system",
};

/**
 * User types who has access to app
 */
export const rolesDict = Object.keys(ROLES);

export const isSystemUser = (profile) => profile?.type === ROLES.system;
export const isSupportUser = (profile) => profile?.type === ROLES.support;
export const isDeveloperUser = (profile) => profile?.type === ROLES.developer;
export const isLeaderUser = (profile) => profile?.type === ROLES.leader;

export const isRestrictedTypeOfUser = (profile) =>
  !rolesDict.includes(profile?.type);
