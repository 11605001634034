import { editEventTableSlice } from "../helpers/editEventTableSlice";

export const EditTemplateSlice = editEventTableSlice({
  reducerName: "templates",
  getListEndpoint: "v1/admin/data/templates",
  getCreateRecordEndpoint: (values) =>
    `v1/admin/events/${values?.event}/templates/registration-form`,
  getUpdateRecordEndpoint: (id) =>
    `v1/admin/events/templates/${id}/registration-form`,
  listTable: ["event", "category", "setting.name", "id", "language", "type"],
  configs: {
    createRecordModal: {
      editTitle: "Edit Template",
    },
    recordEditAdapter: (record) => ({
      ...record,
      name: record["setting.name"],
    }),
    recordActions: [],
  },
});
