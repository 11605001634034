import { createTablePageSlice } from "../helpers/createTablePageSlice";

export const SupportUsersSlice = createTablePageSlice({
  reducerName: "supportUsers",
  getListEndpoint: "v1/admin/users?type=support",
  createRecordEndpoint: "v1/admin/users/support",
  configs: {
    createRecordModal: {
      createTitle: "Create Support User",
      fields: [
        {
          type: "input",
          name: "email",
          label: "Email",
          required: true,
          contentType: "email",
        },
        {
          type: "input",
          name: "first_name",
          label: "First name",
          required: true,
        },
        {
          type: "input",
          name: "last_name",
          label: "Last name",
          required: true,
        },
        {
          type: "input",
          name: "company_name",
          label: "Company name",
        },
        {
          type: "input",
          name: "job_title",
          label: "Job Title",
        },
      ],
    },
  },
});
