import { editEventTableSlice } from "../helpers/editEventTableSlice";

export const EditAccountSlice = editEventTableSlice({
  reducerName: "accounts",
  getListEndpoint: "v1/admin/accounts",
  createRecordEndpoint: "v1/admin/accounts",
  getUpdateRecordEndpoint: (id) => `v1/accounts/${id}`,
  listTable: ["name", "slug", "is_test", "about", "id"],
  configs: {
    createRecordModal: {
      editTitle: "Edit Account",
    },
    recordEditAdapter: (record) => ({
      ...record,
      chat_host: record["chat.host"],
      chat_api_user: record["chat.apiUser"],
      chat_api_key: record["chat.apiKey"],
      chat_secret: record["chat.secret"],
    }),
    recordActions: [],
  },
});
