export const flattenObject = (inputObject) => {
  let result = {};

  for (let objectKey in inputObject) {
    if (!inputObject.hasOwnProperty(objectKey)) continue;

    if (
      typeof inputObject[objectKey] == "object" &&
      inputObject[objectKey] !== null
    ) {
      let flatObject = flattenObject(inputObject[objectKey]);
      for (let objectSubKey in flatObject) {
        if (!flatObject.hasOwnProperty(objectSubKey)) continue;

        result[`${objectKey}.${objectSubKey}`] = flatObject[objectSubKey];
      }
    } else {
      result[objectKey] = inputObject[objectKey];
    }
  }
  return result;
};
