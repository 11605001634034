import axios from "util/Api";
import { datesAdapter } from "../../util/listAdapters";
import { createTablePageSlice } from "../helpers/createTablePageSlice";

export const EventsSlice = createTablePageSlice({
  reducerName: "events",
  getListEndpoint: "v1/admin/data/events",
  listAdapter: datesAdapter([
    "start_date",
    "end_date",
    "created_at",
    "updated_at",
    "deleted_at",
  ]),
  getUpdateRecordEndpoint: (id) => `v1/events/${id}`,
  configs: {
    hideAddButton: true,
    createRecordModal: {
      editTitle: "Edit Event",
      fields: [
        {
          type: "input",
          name: "name",
          label: "Name",
          required: true,
        },
        {
          type: "selectWithRemoteData",
          name: "template_form_registration",
          label: "Registration Template Form",
          getFetchData:
            (recordToEdit = {}) =>
            (callback, fallback) => {
              axios
                .get(`v1/events/${recordToEdit.id}/templates`)
                .then(({ data }) => {
                  if (data?.result?.data) {
                    const registrationFormTemplates = data?.result?.data.filter(
                      (template) => template.type === "registration_form"
                    );
                    const options = [
                      { value: "", text: "(default)" },
                      ...registrationFormTemplates.map((item) => ({
                        value: item.id,
                        text: `${item.setting.name} (id:${item.id})`,
                      })),
                    ];
                    callback(options);
                  }
                })
                .catch((error) => {
                  fallback(error.message);
                });
            },
        },
        {
          type: "checkbox",
          name: "setting.sessionPromotion",
          label: "Session promotion",
          isUpdateOnly: true,
        },
        {
          type: "checkbox",
          name: "published",
          label: "Published Event",
          isUpdateOnly: true,
        },
        {
          type: "datetime",
          getTimezone: (recordToEdit) => recordToEdit?.timezone,
          name: "start_date",
          label: "Start Date/Time",
        },
        {
          type: "datetime",
          getTimezone: (recordToEdit) => recordToEdit?.timezone,
          name: "end_date",
          label: "End Date/Time",
        },
      ],
    },
    recordEditAdapter: (record) => ({
      ...record,
      template_form_registration: record["setting.templateFormRegistration"],
    }),
  },
});
