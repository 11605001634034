import { message } from "antd";
import axios from "util/Api";

import { ROLES } from "../../util/AccessControl";
import { createTablePageSlice } from "../helpers/createTablePageSlice";

export const AccountsSlice = createTablePageSlice({
  reducerName: "accounts",
  getListEndpoint: "v1/admin/accounts",
  createRecordEndpoint: "v1/admin/accounts",
  getUpdateRecordEndpoint: (id) => `v1/accounts/${id}`,
  configs: {
    createRecordModal: {
      createTitle: "Create Account",
      editTitle: "Edit Account",
      fields: [
        {
          type: "input",
          name: "slug",
          label: "Slug",
          required: false,
        },
        {
          type: "input",
          name: "name",
          label: "Name",
          required: true,
        },
        {
          type: "selectWithRemoteData",
          name: "belong_to",
          label: "Owner",
          required: true,
          uneditable: true,
          paramSearch: "email",
          endPoint: "v1/admin/users",
          getFetchData:
            (recordToEdit = {}) =>
            (callback, fallback) => {
              axios
                .get("v1/admin/users")
                .then(({ data }) => {
                  if (data?.result?.data) {
                    const options = data?.result?.data.map((item) => ({
                      value: item.id,
                      text: item.email,
                    }));
                    callback(options);
                  }
                })
                .catch((error) => {
                  fallback(error.message);
                });
            },
        },
        {
          type: "checkbox",
          name: "is_test",
          label: "Test account",
          isUpdateOnly: false,
        },
        {
          type: "input",
          contentType: "url",
          name: "chat_host",
          label: "Chat host",
          required: true,
          uneditable: true,
        },
        {
          type: "input",
          contentType: "email",
          name: "chat_api_user",
          label: "Chat API user",
          required: true,
          uneditable: true,
          minLength: 10,
        },
        {
          type: "input",
          name: "chat_api_key",
          label: "Chat API key",
          required: true,
          uneditable: true,
          minLength: 10,
        },
        {
          type: "input",
          name: "chat_secret",
          label: "Chat Secret",
          required: true,
          uneditable: true,
          minLength: 16,
          maxLength: 16,
        },
      ],
    },
    recordEditAdapter: (record) => ({
      ...record,
      chat_host: record["chat.host"],
      chat_api_user: record["chat.apiUser"],
      chat_api_key: record["chat.apiKey"],
      chat_secret: record["chat.secret"],
    }),
    recordActions: [
      {
        text: "Test chat",
        onClick: ({ record, onFinish }) => {
          const prefix = `[Account "${record.name}"]`;
          axios
            .get(`/v1/system/accounts/${record.id}/chat-status`)
            .then(({ data }) => {
              const configuredCorrectly = data?.result?.status;
              if (configuredCorrectly) {
                message.success(`${prefix} Chat configured correctly`, 3);
              } else {
                message.warn(`${prefix} Chat configuration issue`, 3);
              }
              onFinish();
            })
            .catch((error) => {
              message.error(`${prefix} ${error?.message}`, 3);
              console.error(error);
              onFinish();
            });
        },
        visibleFor: [ROLES.developer, ROLES.system],
      },
    ],
  },
});
