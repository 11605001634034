import axios from "util/Api";

import { createTablePageSlice } from "../helpers/createTablePageSlice";

export const AutomationsSlice = createTablePageSlice({
  reducerName: "automations",
  getListEndpoint: "v1/automations",
  createRecordEndpoint: "v1/automations",
  getUpdateRecordEndpoint: (id) => `v1/automations/${id}`,
  graphEndpoint: "",
  configs: {
    createRecordModal: {
      createTitle: "Create Automation",
      editTitle: "Update Automation",
      fields: [
        {
          type: "input",
          name: "name",
          label: "Name",
          required: true,
        },
        {
          type: "input",
          name: "description",
          label: "Description",
          required: false,
        },
        {
          type: "multiSelectWithRemoteData",
          name: "events",
          label: "Events",
          required: false,
          fetchData: (callback, fallback) => {
            axios
              .get("v1/admin/data/events")
              .then(({ data }) => {
                if (data?.result?.data) {
                  const options = data?.result?.data.map((item) => ({
                    value: item.id,
                    text: `${item.name} (id:${item.id})`,
                  }));
                  callback(options);
                }
              })
              .catch((error) => {
                fallback(error.message);
              });
          },
        },
        {
          type: "checkbox",
          name: "published",
          label: "Published",
          required: false,
          isUpdateOnly: true,
        },
        {
          type: "graphEditor",
          name: "graphEditor",
          label: "Graph Editor",
          required: false,
          isUpdateOnly: true,
        },
      ],
    },
    recordActions: [
      {
        text: "Edit",
        onClick: ({ record, onFinish, onEditRecord = () => {} }) => {
          onEditRecord(record);
          setTimeout(onFinish, 200);
        },
      },
    ],
    recordEditAdapter: (record) => ({
      ...record,
      name: record["setting.name"],
    }),
  },
});
