import axios from "util/Api";
import { createTablePageSlice } from "../../helpers/createTablePageSlice";
import { registrationFormContentHtmlInitialValue } from "./initialValues";

export const TemplatesSlice = createTablePageSlice({
  reducerName: "templates",
  getListEndpoint: "v1/admin/data/templates",
  createRecordEndpoint: "v1/templates", // deprecated. TBD refactoring
  getCreateRecordEndpoint: (values) =>
    `v1/admin/events/${values?.event}/templates/registration-form`,
  getUpdateRecordEndpoint: (id) =>
    `v1/admin/events/templates/${id}/registration-form`,
  configs: {
    createRecordModal: {
      createTitle: "Create registration-form template",
      editTitle: "Edit registration-form template",
      fields: [
        {
          type: "selectWithRemoteData",
          name: "event",
          label: "Event",
          required: true,

          getFetchData:
            (recordToEdit = {}) =>
            (callback, fallback) => {
              axios
                .get("v1/admin/data/events")
                .then(({ data }) => {
                  if (data?.result?.data) {
                    const options = data?.result?.data.map((item) => ({
                      value: item.id,
                      text: `${item.name} (id:${item.id})`,
                    }));
                    callback(options);
                  }
                })
                .catch((error) => {
                  fallback(error.message);
                });
            },
          uneditable: true,
        },
        {
          type: "input",
          name: "name",
          label: "Name",
          required: true,
        },
        {
          type: "select",
          name: "language",
          label: "Language",
          required: true,
          options: [
            {
              text: "de",
              value: "de",
            },
            {
              text: "en",
              value: "en",
            },
            // TBD
          ],
        },
        {
          type: "htmlEditor",
          name: "content_html",
          label: "Content HTML",
          initialValue: registrationFormContentHtmlInitialValue,
        },
      ],
    },
    recordEditAdapter: (record) => ({
      ...record,
      name: record["setting.name"],
    }),
  },
});
