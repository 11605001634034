import { message } from "antd";

import api from "util/Api";

import { createTablePageSlice } from "../helpers/createTablePageSlice";

export const ScheduledJobsSlice = createTablePageSlice({
  reducerName: "scheduledJobs",
  getListEndpoint: "v1/system/jobs",
});

export const FailedJobsSlice = createTablePageSlice({
  reducerName: "failedJobs",
  getListEndpoint: "v1/system/jobs/failed",
  configs: {
    tableActions: [
      {
        name: "Retry All",
        onClick: ({ onFinish }) => {
          api
            .get("v1/system/jobs/failed/retry")
            .then(({ data }) => {
              if (data?.status === 200) {
                message.success("Failed jobs were retried");
              } else {
                message.warning(data.message);
              }
              onFinish();
            })
            .catch((error) => {
              message.error(error?.message);
              onFinish();
            });
        },
      },
    ],
  },
});
