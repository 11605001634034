import { message } from "antd";
import { datesAdapter } from "../../util/listAdapters";
import { editEventTableSlice } from "../helpers/editEventTableSlice";

export const EditEventSlice = editEventTableSlice({
  reducerName: "events",
  getListEndpoint: "v1/admin/data/events",
  listAdapter: datesAdapter([
    "start_date",
    "end_date",
    "created_at",
    "updated_at",
    "deleted_at",
  ]),
  listTable: ["id", "name", "slug", "start_date", "end_date"],
  getUpdateRecordEndpoint: (id) => `v1/events/${id}`,
  configs: {
    hideAddButton: false,
    mapColumnTitles: true,
    createRecordModal: { editTitle: "Edit Event" },
    recordEditAdapter: (record) => ({
      ...record,
      template_form_registration: record["setting.templateFormRegistration"],
    }),
    recordActions: [
      {
        text: "Copy Event ID",
        onClick: ({ record, onFinish }) => {
          navigator.clipboard.writeText(record?.id);
          message.success("Event ID copied to clipboard!");
          setTimeout(onFinish, 200);
        },
      },
      {
        text: "Copy Event name",
        onClick: ({ record, onFinish }) => {
          navigator.clipboard.writeText(record?.name);
          message.success("Event name copied to clipboard!");
          setTimeout(onFinish, 200);
        },
      },
      {
        text: "Copy Event slug ",
        onClick: ({ record, onFinish }) => {
          navigator.clipboard.writeText(record?.slug);
          message.success("Event slug copied to clipboard!");
          setTimeout(onFinish, 200);
        },
      },
    ],
  },
});
