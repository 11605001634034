import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  UPDATE_LOAD_USER,
  USER_DATA,
  USER_TOKEN_SET,
  SIGNIN_USER_SUCCESS,
  SHOW_MESSAGE,
} from "../../constants/ActionTypes";
import axios, { getApiBaseURL } from "util/Api";
import { isOk } from "../../util/httpHelpers";
import { isRestrictedTypeOfUser } from "../../util/AccessControl";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const userSignIn = ({ email, password }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("v1/auth/login", { email, password })
      .then(({ data }) => {
        if (data.result) {
          localStorage.setItem("token", data.result.token);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + data.result.token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.result.token });
          dispatch(getProfile(data.result.token));
          dispatch({ type: SIGNIN_USER_SUCCESS, payload: data.result.token });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const getProfile = (token) => {
  return (dispatch) => {
    if (!token) {
      token = localStorage.getItem("token");
    }
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    dispatch({ type: FETCH_START });
    axios
      .get("v1/users/profile")
      .then(({ data }) => {
        if (data.result) {
          dispatch({ type: FETCH_SUCCESS });
          const profile = data?.result?.data;
          if (isRestrictedTypeOfUser(profile)) {
            dispatch(userSignOut());
            setTimeout(() => {
              dispatch({ type: FETCH_ERROR, payload: "Access is denied" });
            }, 500);
            return;
          }
          const userData = {
            firstName: profile?.first_name,
            lastName: profile?.last_name,
            fullName: `${profile?.first_name} ${profile?.last_name}`,
            email: profile?.email,
            type: profile?.type,
          };
          dispatch({ type: USER_DATA, payload: userData });
          localStorage.setItem("user_id", data.result.data.id);
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        const isUnauthorizedResponse =
          error.message === "Request failed with status code 401";
        const isUserAuthorized = !!(
          localStorage.getItem("token") && localStorage.getItem("user_id")
        );
        if (isUnauthorizedResponse && isUserAuthorized) dispatch(userSignOut());
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: UPDATE_LOAD_USER, payload: false });
      });
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    axios
      .get("v1/auth/logout")
      .then(({ data }) => {
        if (isOk(data.status)) {
          dispatch({ type: USER_DATA, payload: null });
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SIGNOUT_USER_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const userPasswordReset = ({ email }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    axios
      .post(`${getApiBaseURL()}/v1/activation/password-reset-request`, {
        email,
      })
      .then(({ data }) => {
        if (isOk(data.status)) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: "Password reset instructions have been sent to your email",
          });
          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
