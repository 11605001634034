import { message } from "antd";
import axios from "util/Api";
import { flattenObject } from "util/Object";
import { isOk } from "../../util/httpHelpers";
import { filtered } from "../../util/listAdapters";

const initialState = {
  loader: false,
  data: [],
  createEditLoader: false,
  createEditError: "",
  isCreatedEdited: false,
};

export const editEventTableSlice = ({
  reducerName,
  getListEndpoint = "v1/admin/data/events",
  createRecordEndpoint = "v1/users",
  getCreateRecordEndpoint,
  listTable,
  getUpdateRecordEndpoint = (id) =>
    `v1/admin/events/templates/${id}/registration-form`,
  configs,
  listAdapter = (flatRecord) => flatRecord,
}) => {
  const uppercasedReducerName = reducerName.toUpperCase();
  const LOADING_START = `${uppercasedReducerName}_LOADING_START`;
  const LOADING_FINISH = `${uppercasedReducerName}_LOADING_FINISH`;
  const SET_LIST = `SET_${uppercasedReducerName}_LIST`;
  const SET_META = `SET_${uppercasedReducerName}_META`;
  const CREATE_EDIT_LOADING_START = `CREATE_EDIT_${uppercasedReducerName}_LOADING_START`;
  const CREATE_EDIT_LOADING_FINISH = `CREATE_EDIT_${uppercasedReducerName}_LOADING_FINISH`;
  const CREATE_EDIT_ERROR = `CREATE_EDIT_${uppercasedReducerName}_ERROR`;
  const CREATE_EDIT_SUCCESS = `CREATE_EDIT_${uppercasedReducerName}_SUCCESS`;
  const CREATE_EDIT_RESET = `CREATE_EDIT_${uppercasedReducerName}_RESET`;

  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case LOADING_START: {
        return {
          ...state,
          loader: true,
        };
      }
      case LOADING_FINISH: {
        return {
          ...state,
          loader: false,
        };
      }
      case SET_LIST: {
        return {
          ...state,
          data: action.payload,
        };
      }
      case SET_META: {
        return {
          ...state,
          data: action.payload,
        };
      }
      case CREATE_EDIT_LOADING_START: {
        return {
          ...state,
          createEditLoader: true,
        };
      }
      case CREATE_EDIT_LOADING_FINISH: {
        return {
          ...state,
          createEditLoader: false,
        };
      }
      case CREATE_EDIT_ERROR: {
        return {
          ...state,
          createEditError: action.payload,
        };
      }
      case CREATE_EDIT_SUCCESS: {
        return {
          ...state,
          isCreatedEdited: true,
        };
      }
      case CREATE_EDIT_RESET: {
        return {
          ...state,
          createEditLoader: false,
          createEditError: "",
          isCreatedEdited: false,
        };
      }
      default:
        return state;
    }
  };

  const actions = {
    fetchList: (listEndPoint) => {
      return (dispatch) => {
        dispatch({ type: LOADING_START });
        axios
          .get(listEndPoint || getListEndpoint)
          .then(({ data }) => {
            const listData = data?.result?.data || data?.result;
            const allowedListData = listData
              .map(flattenObject)
              .map(listAdapter)
              .map((e) => filtered(e, listTable));
            const metaData = data?.result?.meta || data?.meta;
            const payload = allowedListData;
            dispatch({ type: SET_LIST, payload });
            dispatch({ type: SET_META, metaData });
            dispatch({ type: LOADING_FINISH });
          })
          .catch((error) => {
            message.error(
              `${error?.message}. ${error?.response?.data?.message}`
            );
            dispatch({ type: LOADING_FINISH });
          });
      };
    },
    resetList: () => {
      return (dispatch) => {
        dispatch({ type: SET_LIST, payload: [] });
        dispatch({ type: SET_META, payload: [] });
      };
    },
    createRecord: (values) => {
      return (dispatch) => {
        dispatch(actions.resetCreateEdit());
        dispatch({ type: CREATE_EDIT_LOADING_START });

        const endpoint = getCreateRecordEndpoint
          ? getCreateRecordEndpoint(values)
          : createRecordEndpoint;

        axios
          .post(endpoint, values)
          .then(({ data }) => {
            if (isOk(data.status)) {
              dispatch({ type: CREATE_EDIT_LOADING_FINISH });
              dispatch({ type: CREATE_EDIT_SUCCESS });

              dispatch(actions.fetchList());
            } else {
              dispatch({
                type: CREATE_EDIT_ERROR,
                payload: "Something went wrong...",
              });
              dispatch({ type: CREATE_EDIT_LOADING_FINISH });
            }
          })
          .catch((error) => {
            const invalidFields = error?.response?.data?.invalid_fields;
            const invalidFieldsLines = invalidFields
              ? Object.values(invalidFields).flat()
              : [];
            const errorMessage = [error.message, ...invalidFieldsLines].join(
              ". "
            );
            dispatch({
              type: CREATE_EDIT_ERROR,
              payload: errorMessage,
            });
            dispatch({ type: CREATE_EDIT_LOADING_FINISH });
          });
      };
    },
    updateRecord: (id, values) => {
      return (dispatch) => {
        dispatch(actions.resetCreateEdit());
        dispatch({ type: CREATE_EDIT_LOADING_START });
        axios
          .post(getUpdateRecordEndpoint(id), { ...values, _method: "put" })
          .then(({ data }) => {
            if (isOk(data.status)) {
              dispatch({ type: CREATE_EDIT_LOADING_FINISH });
              dispatch({ type: CREATE_EDIT_SUCCESS });

              dispatch(actions.fetchList());
            } else {
              dispatch({
                type: CREATE_EDIT_ERROR,
                payload: "Something went wrong...",
              });
              dispatch({ type: CREATE_EDIT_LOADING_FINISH });
            }
          })
          .catch((error) => {
            dispatch({ type: CREATE_EDIT_ERROR, payload: error.message });
            dispatch({ type: CREATE_EDIT_LOADING_FINISH });
          });
      };
    },
    resetCreateEdit: () => (dispatch) => dispatch({ type: CREATE_EDIT_RESET }),
  };

  const selectors = {
    getList: (state) => state[reducerName].data,
    getListLoaderState: (state) => state[reducerName].loader,
    getCreateEditLoaderState: (state) => state[reducerName].createEditLoader,
    getCreateEditError: (state) => state[reducerName].createEditError,
    getIsCreatedEdited: (state) => state[reducerName].isCreatedEdited,
  };

  const configsTotal = { ...configs, getListEndpoint: getListEndpoint };
  return {
    reducer,
    actions,
    selectors,
    configs: configsTotal,
  };
};
